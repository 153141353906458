<template>
  <modal 
    :name="name"
    styles="border-radius: 12px;"
    :width="width"
    :height="height"
    :adaptive="true"
    :clickToClose="false"
  >
    <div class="modal-with-field-wrapper">
      <div class="modal-with-field-title">{{title}}</div>
      <InputRadio
        v-if="field === 'radio'"
        :name="`${name}-field`"
        class="type-entre"
        :label="label"
        :valid="true"
        :shouldValidate="false"
        :value="value"
        :val="options"
        @change="onChange"
      />
      <TextArea
        v-else-if="field === 'textarea'"
        :name="`${name}-field`"
        :label="label"
        :valid="rule(value)"
        :shouldValidate="false"
        :value="value"
        :invalidityInfo="invalidityInfo"
        @change="onChange"
      />
      <Select
        v-else-if="field === 'select'"
        :name="`${name}-field`"
        :label="label"
        :valid="rule(value)"
        :shouldValidate="false"
        :value="value"
        :options="options"
        :invalidityInfo="invalidityInfo"
        @change="onChange"
      />
      <Input
        v-else-if="field === 'input'"
        :name="`${name}-field`"
        :label="label"
        :mask="mask"
        :valid="rule(value)"
        :shouldValidate="false"
        :value="value"
        :invalidityInfo="invalidityInfo"
        @change="onChange"
      />
      <div class="modal-with-field-description">
        <span>{{description}}</span>
      </div>
      <div class="modal-with-field-buttons">
        <Button @click="handleCancel">Отмена</Button>
        <Button @click="handleOk" :disabled="!(value && rule(value))">Ok</Button>
      </div>
    </div>
  </modal>
</template>

<script>
  import Vue from "vue";

  import Button from "@/components/UI/Button.vue";
  import Select from "@/components/UI/form/Select.vue";
  import TextArea from "@/components/UI/form/TextArea.vue";
  import InputRadio from "@/components/UI/form/InputRadio.vue";
  import Input from "@/components/UI/form/Input.vue";

  export default Vue.extend({
    name: "ModalWithField",

    components: {Button, InputRadio, TextArea, Select, Input},

    props: {
      name: {
        type: String,
        required: true
      },
      width: {
        type: String,
        required: true
      },
      height: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: false,
        default: ""
      },
      field: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: false,
        default: ""
      },
      defaultValue: {
        required: false,
        default: ""
      },
      options: {
        type: Array,
        required: false,
        default: () => []
      },
      mask: {
        type: String,
        required: false,
        default: ""
      },
      rule: {
        type: [Function],
        required: false,
        default: () => true
      },
      invalidityInfo: {
        type: String,
        required: false,
        default: ""
      }
    },

    data() {
      return {
        value: this.defaultValue
      }
    },

    methods: {
      handleOk() {
        this.$emit("onClickOk", this.value);
        this.value = this.defaultValue;
        this.$modal.hide(this.name);
      },

      handleCancel() {
        this.$emit("onClickCancel", this.value);
        this.value = this.defaultValue;
        this.$modal.hide(this.name);
      },

      onChange(evt) {
        const {value} = evt.target;
        this.value = value;
        
      }
    },
  })
</script>

<style lang="scss">
  .modal-with-field-wrapper {
    width: 100%;
    padding: 45px;
    text-align: center;

    .form-item-radio {
      width: 50%;
    }
  }
  .modal-with-field-title {
    font-size: 20px;
    font-weight: 700;
    color: #333;
    padding-bottom: 25px;
  }
  .modal-with-field-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
  }
  .modal-with-field-description {
    text-align: justify;
    padding: 25px 0 10px 0;
  }
</style>
